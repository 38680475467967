import logo from './simea4.svg';
import './App.css';
//import Game from './Game';
//import { BrowserRouter, Switch, Route, Link } from "react-router-dom";

function App() {
  return (

<div>
    <section className="section--start">
        <div className="section--wrapper">
            <img className="logo scale-in-center" src={logo} />
            <h1 className="show2">Jarosław Nogala<br />+48 <span>691 533 466</span></h1>
        </div>
    </section>


    <footer className="footer">
        <p>Copyright &copy;2000-2022 SIMEA.PL Jarosław Nogala. All rights reserved.</p>
    </footer>
    </div>

  );
}

export default App;
